import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { toast } from 'react-toastify'

function ContactUs() {
  //   const [firstName, setFirstName] = useState('')
  //   const [lastName, setLastName] = useState('')
  //   const [phone, setPhone] = useState('')
  //   const [email, setEmail] = useState('')

  //   const onClick = () => {
  //     if (firstName.length == 0) {
  //       toast.error('Please enter your first name')
  //     } else if (lastName.length == 0) {
  //       toast.error('Please enter your last name')
  //     } else if (email.length == 0) {
  //       toast.error('Please enter your email')
  //     } else if (phone.length == 0) {
  //       toast.error('Please enter your phone')
  //     } else {
  //     }
  //   }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCard className="p-4">
              <CCardBody>
                <CForm>
                  <h2 style={{ textAlign: 'center' }}>Contact Us</h2>
                  <div className="mt-4">
                    <h4>Email</h4>
                    s9860330096@gmail.com
                  </div>
                  <div className="mt-4">
                    <h4>Phone Number</h4>
                    +91 98603 30096
                  </div>
                  <div className="mt-4">
                    <h4>Address</h4>
                    Koutilya Konusltancy, 1025 B Sadashiv Peth , Pune 411030
                  </div>

                  {/* <CRow>
                    <CCol className="mb-3 mt-3">
                      <label htmlFor="">First Name</label>
                      <CFormInput onChange={(e) => setFirstName(e.target.value)} />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className="mb-3">
                      <label htmlFor="">Last Name</label>
                      <CFormInput onChange={(e) => setLastName(e.target.value)} />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className="mb-3">
                      <label htmlFor="">Email</label>
                      <CFormInput onChange={(e) => setEmail(e.target.value)} />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className="mb-3">
                      <label htmlFor="">Phone Number</label>
                      <CFormInput onChange={(e) => setPhone(e.target.value)} />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol xs={6}>
                      <CButton color="primary" className="px-4" onClick={onClick}>
                        Send
                      </CButton>
                    </CCol>
                  </CRow> */}
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ContactUs
